import * as React from "react"
import { useState, useEffect } from "react"
import Layout from "../../components/Layout"
import Container from "../../components/Container"
import Badge from "../../components/Badge"
import { StaticImage } from "gatsby-plugin-image"
import ReactModal from 'react-modal';
import { MdClose, MdArrowForward, MdArrowBack } from "react-icons/md"

const Zactly = () => {
  let [image, setImage] = useState(null);

  let [modalOpen, setModalOpen] = useState(false);

  const renderStaticImage = () => {
    if (image === 1) {
      return <StaticImage aspectRatio={16/9} placeholder="blurred" src="../../../src/images/freelance/zactly/1_large.jpg" alt="" className="object-cover" />;
    } else if (image === 2) {
      return <StaticImage aspectRatio={16/9} placeholder="blurred" src="../../../src/images/freelance/zactly/2_large.jpg" alt="" className="object-cover" />;
    } else if (image === 3) {
      return <StaticImage aspectRatio={16/9} placeholder="blurred" src="../../../src/images/freelance/zactly/3_large.jpg" alt="" className="object-cover" />;
    } else if (image === 4) {
      return <StaticImage aspectRatio={16/9} placeholder="blurred" src="../../../src/images/freelance/zactly/4_large.jpg" alt="" className="object-cover" />;
    }
  }

  const renderCaption = () => {
    if (image === 1) {
      return <p className="text-sm">Home page design.</p>;
    } else if (image === 2) {
      return <p className="text-sm">An example project listing all associated assumptions, categorised as cards in lists.</p>;
    } else if (image === 3) {
      return <p className="text-sm">Zactly allows you to create personas that can then be mapped to specific assumptions.</p>;
    } else if (image === 4) {
      return <p className="text-sm">A list of all research actions underway.</p>;
    }
  }

  const leftArrow = () => {
    if (image === 1) {
      setImage(image = 4);
    } else {
      setImage(image = image - 1);
    }
  }

  const rightArrow = () => {
    if (image === 4) {
      setImage(image = 1);
    } else {
      setImage(image = image + 1);
    }
  }

  useEffect(() => {
    if (typeof document !== "undefined") {
      if (modalOpen === true) {
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = 'scroll'
      }
    }
  },[modalOpen]);

  return (
    <Layout>
      <div>
        <Container showFooter colour="0d1120" title="Zactly" backLink>
          <div className="flex">
              <div className="mx-auto">
                <StaticImage placeholder="blurred" alt="" src="../../../src/images/freelance/zactly-logo.png" className="rounded-xl shadow-lg w-40 mb-10" />
              </div>
          </div>

          <p className="mb-6 max-w-prose text-accent-text">Zactly was built as a solo hobby project that I intended to use personally when carrying out design or research in my day-to-day work. Having enjoyed the project so much, and after getting some positive feedback from colleagues, I may make it public in future.</p>

          <p className="mb-6 max-w-prose text-accent-text">Zactly is a validation toolkit for any creative process. Whether you're designing a new app, planning a marketing campaign or launching your own service business, Zactly lets you keep track of all the assumptions you made during your projects, keep your target audience in mind, and kick off research to help validate your ideas.</p>

          <div className="mb-16">
            <Badge label="UX/UI" />

            <Badge label="Back-end (Python/Django)" />

            <Badge label="Front-end (Vue.js)" />

            <Badge label="Branding & Illustrations" />

            <Badge label="Motion design" />
          </div>

{/*         
          <div className="flex mb-16">
              <div className="mx-auto">
                  <a href="https://zactly.app" rel="noreferrer" target="_blank">
                    <button className={'p-2 pl-5 pr-5 bg-transparent border-2 border-main-text text-main-text rounded-xl font-bold transition-colors duration-200 transform hover:bg-white hover:text-black focus:border-2 shadow-lg'}>Visit Zactly</button>
                  </a>
              </div>
          </div>
 */}

          <div className="grid md:grid-cols-2 gap-12">
            <div onClick={() => {setModalOpen(true); setImage(1)}}>
              <StaticImage placeholder="blurred" alt="Zactly Home Page" src="../../../src/images/freelance/zactly/1_thumb.jpg" className="rounded-xl shadow-lg hover:opacity-80 cursor-pointer transition-opacity duration-200 transform" />
            </div>
            
            <div onClick={() => {setModalOpen(true); setImage(2)}}>
              <StaticImage placeholder="blurred" alt="" src="../../../src/images/freelance/zactly/2_thumb.jpg" className="rounded-xl shadow-lg hover:opacity-80 cursor-pointer transition-opacity duration-200 transform" />
            </div>

            <div onClick={() => {setModalOpen(true); setImage(3)}}>
              <StaticImage placeholder="blurred" alt="" src="../../../src/images/freelance/zactly/3_thumb.jpg" className="rounded-xl shadow-lg hover:opacity-80 cursor-pointer transition-opacity duration-200 transform" />
            </div>

            <div onClick={() => {setModalOpen(true); setImage(4)}}>
              <StaticImage placeholder="blurred" alt="" src="../../../src/images/freelance/zactly/4_thumb.jpg" className="rounded-xl shadow-lg hover:opacity-80 cursor-pointer transition-opacity duration-200 transfdorm" />
            </div>

            <ReactModal
              isOpen={modalOpen}
              shouldCloseOnEsc={true}
              shouldReturnFocusAfterClose={true}
              shouldCloseOnOverlayClick={true}
              ariaHideApp={false}
              onRequestClose={() => {
                setModalOpen(false)
              }}
              className="grid bg-ink-500 text-white lg:rounded-xl shadow-2xl m-4 animate-fade-in-new max-w-screen-lg mx-auto"
              overlayClassName="fixed text-gray-500 items-center overflow-y-scroll justify-center z-50 bg-black bg-opacity-80 left-0 right-0 top-0 bottom-0"
            >
              <div className="grid">
                <div className="ml-auto">
                  <div className="py-6 px-3 sm:px-6 sm:py-6 cursor-pointer">
                    <MdClose className="text-2xl text-white hover:text-gray-300 transition-colors duration-200 transform" onClick={() => setModalOpen(false)} />
                  </div>
                </div>
              </div>

              <div>
                {renderStaticImage()}
              </div>

              <div className="">
                <div className="flex justify-between">
                  <div className="py-6 px-3 sm:px-6 sm:py-6 cursor-pointer"><MdArrowBack className="text-2xl text-white hover:text-gray-300 transition-colors duration-200 transform" onClick={() => leftArrow()} /></div>
                  <div className="py-6 px-3 sm:px-6 sm:py-6">{renderCaption()}</div>
                  <div className="py-6 px-3 sm:px-6 sm:py-6 cursor-pointer"><MdArrowForward className="text-2xl text-white hover:text-gray-300 transition-colors duration-200 transform" onClick={() => rightArrow()} /></div>
                </div>
              </div>
            </ReactModal>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default Zactly
